/**
 * Vendors
 */
import Rellax from "rellax";

/**
 * Helpers
 */
import { fromBreakpoint, onVisibilityChange, toBreakpoint } from "./helpers";

/**
 * Web standards
 */
import { modal } from "./modal";
import "./softscroll";
import "./header.video";
import "./lazyload";
import "alpinejs/dist/cdn.min.js";
import "./persoonlijkheidstest";

// window.acf.doAction =  () => {};


/**
 * Dynamic import scripts
 */
const elements = [
  ".rotate-on-scroll",
  "[data-aos]",
  ".animate-count",
  "#logos",
  "#testimonials",
  "#testimonials_block",
  "#traineeship_swiper",
  "#steps-slider",
  "#job_search",
  ".random-images-container",
  "#hero-swiper",
  "",
];

[].forEach.call(elements, (element) => {
  if (element !== "") {
    if (
      document.querySelector(element) !== null &&
      document.querySelector(element) !== undefined
    ) {
      const lazyloadHandler = onVisibilityChange(
        document.querySelector(element),
        function () {
          if (element === ".rotate-on-scroll") {
            const rotateOnScroll = import("./rotateOnScroll");
          }

          if (element === ".animate-count") {
            const animateCount = import("./animateCount");
          }

          if (element === "[data-aos]") {
            const AOS = import("aos").then((AOS) => {
              AOS.init({
                disable: function () {
                  return /bot|googlebot|crawler|spider|robot|crawling/i.test(
                    navigator.userAgent
                  );
                },
              });
            });
          }

          if (element === "#logos") {
            const logos = import("./logo.swiper");
            const swiperCSS = import("swiper/css/bundle");
          }

          if (element === "#testimonials") {
            const testimonials = import("./testimonials.swiper");
            const swiperCSS = import("swiper/css/bundle");
            const swiperPagination = import("swiper/css/pagination");
          }

          if (element === "#testimonials_block") {
            const testimonialsBlock = import("./testimonials_block.swiper");
            const swiperCSS = import("swiper/css/bundle");
            const swipernavigation = import("swiper/css/navigation");
          }

          if (element === "#traineeship_swiper") {
            const traineeships = import("./traineeships.swiper");
            const swiperCSSTraineeships = import("swiper/css/bundle");
          }

          if (element === "#steps-slider") {
            const stepsSlider = import("./steps.swiper");
            const swiperCSSSteps = import("swiper/css/bundle");
            const swiperNavigationSteps = import("swiper/css/navigation");
          }

          if (element === ".random-images-container") {
            const randomImages = import("./randomImages");
          }

          if (element === "#job_search") {
            const filters = import("./filters");
          }

          if (element === "#hero-swiper") {
            const heroSwiper = import("./hero.swiper");
            const swiperCSS = import("swiper/css/bundle");
            const swiperPagination = import("swiper/css/pagination");
          }
        }
      );

      if (window.addEventListener) {
        addEventListener("DOMContentLoaded", lazyloadHandler, false);
        addEventListener("load", lazyloadHandler, false);
        addEventListener("scroll", lazyloadHandler, false);
        addEventListener("resize", lazyloadHandler, false);
      } else if (window.attachEvent) {
        attachEvent("onDOMContentLoaded", lazyloadHandler); // Internet Explorer 9+ :(
        attachEvent("onload", lazyloadHandler);
        attachEvent("onscroll", lazyloadHandler);
        attachEvent("onresize", lazyloadHandler);
      }
    }
  }
});

document.addEventListener("DOMContentLoaded", () => {
  if (document.querySelectorAll("div[data-modal-target]").length > 0) {
    modal.init();
  }

  if (document.querySelector(".rellax")) {
    const rellax = new Rellax(".rellax", {
      center: false,
      wrapper: null,
      round: true,
      vertical: true,
      horizontal: false,
    });
  }
  const forms = document.querySelectorAll("form");
  forms.forEach((form) => {
    /**
     * Check if form is multitab
     */
    if (form.id && document.querySelector(`#${form.id}` + " .acf-tab-group")) {
      const liEls = document.querySelectorAll(
        `#${form.id}` + " .acf-tab-group li a"
      );
      const nextButton = document.querySelector(`#${form.id}` + " #next-step");

      document
        .querySelector(`#${form.id}` + " .acf-form-submit")
        .classList.add("hidden");

      const currentIndexInput = document.createElement("input");
      currentIndexInput.type = "hidden";
      currentIndexInput.name = "acf_tab_group_current_tab";
      currentIndexInput.value = 0;
      form.appendChild(currentIndexInput);

      const backButton = document.createElement("button");
      backButton.type = "button";
      backButton.id = "back-step";
      backButton.classList.add("btn", "btn-blue-outline", "hidden");
      backButton.innerHTML = "Terug";
      document
        .querySelector(`#${form.id}` + " .acf-form-submit")
        .prepend(backButton);

      const tabs = document.querySelectorAll(`#${form.id}` + " .acf-field-tab");
      tabs.forEach((tab, index) => {
        tab.dataset.index = index;
      });

      document
        .querySelector('a[data-key="' + liEls[0].dataset.key + '"]')
        .click();

      backButton.addEventListener("click", (e) => {
        e.preventDefault();
        const currentIndex = parseInt(currentIndexInput.value);
        const currentLi = [...liEls].filter((liEl, index) => {
          return index === currentIndex;
        })[0];
        const nextLi = [...liEls].filter((liEl, index) => {
          return index === currentIndex - 1;
        })[0];

        const nextIndex = currentIndex - 1;
        currentIndexInput.value = nextIndex;

        if (nextIndex + 1 === liEls.length) {
          nextButton.classList.add("hidden");
          backButton.classList.remove("hidden");
          document
            .querySelector(`#${form.id}` + " .acf-form-submit")
            .classList.remove("hidden");
        } else {
          nextButton.classList.remove("hidden");
          backButton.classList.add("hidden");
          document
            .querySelector(`#${form.id}` + " .acf-form-submit")
            .classList.add("hidden");
        }

        document
          .querySelector('a[data-key="' + nextLi.dataset.key + '"]')
          .click();
      });

      nextButton.addEventListener("click", (e) => {
        e.preventDefault();
        const currentIndex = parseInt(currentIndexInput.value);
        const currentLi = [...liEls].filter((liEl, index) => {
          return index === currentIndex;
        })[0];
        const nextLi = [...liEls].filter((liEl, index) => {
          return index === currentIndex + 1;
        })[0];

        const nextIndex = currentIndex + 1;
        currentIndexInput.value = nextIndex;

        if (nextIndex + 1 === liEls.length) {
          nextButton.classList.add("hidden");
          backButton.classList.remove("hidden");
          document
            .querySelector(`#${form.id}` + " .acf-form-submit")
            .classList.remove("hidden");
        } else {
          nextButton.classList.remove("hidden");
          backButton.classList.add("hidden");
          document
            .querySelector(`#${form.id}` + " .acf-form-submit")
            .classList.add("hidden");
        }
        document
          .querySelector('a[data-key="' + nextLi.dataset.key + '"]')
          .click();
      });
    }

    var fileInput = document.querySelector(
      '.acf-basic-uploader input[type="file"]'
    );
    if (fileInput) {
      var acfInput = fileInput.closest(".acf-input");
      var showIfValue = acfInput.querySelector(".show-if-value");
      var hideIfValue = acfInput.querySelector(".hide-if-value");
      var fileInfo = showIfValue.querySelector(".file-info");
      var fileIcon = showIfValue.querySelector(".file-icon img");
      var removeButton = showIfValue.querySelector(".acf-icon.-cancel");
      if (showIfValue) {
        showIfValue.style.display = "none";
      }

      fileInput.addEventListener("change", function (event) {
        var file = event.target.files[0];
        if (file) {
          showIfValue.style.display = "block";
          fileIcon.src = ""; // Set a default icon based on the file type if needed
          fileInfo.querySelector('[data-name="title"]').textContent = file.name;
          fileInfo.querySelector('[data-name="filename"]').textContent =
            file.name;
          fileInfo.querySelector('[data-name="filesize"]').textContent =
            (file.size / 1024).toFixed(2) + " KB";

          acfInput.classList.add("has-value");
        }
      });
    }
    if (removeButton) {
      removeButton.addEventListener("click", function (event) {
        event.preventDefault();
        fileInput.value = ""; // Clear the file input
        acfInput.classList.remove("has-value");
      });
    }
  });
});
