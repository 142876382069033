class Quiz {
  constructor(questions) {
    this.questions = questions;
    this.currentQuestionIndex =
      parseInt(localStorage.getItem("quiz_current_question")) || 0;
    this.answers = JSON.parse(localStorage.getItem("quiz_answers")) || [];
    this.totalScore = 0;
    this.name = localStorage.getItem("quiz_name") || "";
  }

  start() {
    if (this.name) {
      this.showQuestion();
      this.renderTimeline();
      this.updateTimeline();
    } else {
      this.showStartScreen();
    }
  }

  setContent() {
    const contentPlaceholder = document.getElementById("content-placeholder");
    const form = new FormData();
    form.append(
      "action",
      "persoonlijkheidstest_get_content_based_on_totalscore"
    );
    form.append("totalscore", this.totalScore);

    const params = new URLSearchParams(form);

    fetch(prikr_persoonlijkheidstest_ajax.ajaxurl, {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: params,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data) {
          contentPlaceholder.innerHTML = data.data.html;
        }
        const nameFieldContainer = document.querySelector(
          '.acf-field[data-name="name"]'
        );
        if (nameFieldContainer) {
          const nameField =
            nameFieldContainer.querySelector('input[type="text"]');
          if (nameField) {
            nameField.value = this.name;
          }
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  saveToDb() {
    const form = new FormData();
    form.append("action", "persoonlijkheidstest_save_results");
    form.append("totalscore", this.totalScore);
    form.append("name", this.name);
    form.append("answers", JSON.stringify(this.answers));

    // questions with answers
    const questionsWithAnswers = this.questions.map((question, index) => {
      return {
        question: question.question,
        givenAnswerLabelAndScore: question.answers.map(
          (answer, answerIndex) => {
            if (Array.isArray(this.answers[index])) {
              if (this.answers[index].includes(answerIndex.toString())) {
                return {
                  label: answer.label,
                  score: answer.score,
                };
              }
            } else {
              if (this.answers[index] == answerIndex) {
                return {
                  label: answer.label,
                  score: answer.score,
                };
              }
            }
          }
        ).filter((answer) => answer),
      };
    });

    // Can we make this readable, per sentence a question and the answer beneath it with the score?
    const readableQuestionsWithAnswers = questionsWithAnswers.map(
      (question, index) => {
        return `${question.question}:\n${question.givenAnswerLabelAndScore.map(
          (answer) => `• ${answer.label} (${answer.score} punten)`
        ).join("\n")}`;
      }
    );

    form.append("questions", JSON.stringify(readableQuestionsWithAnswers));

    fetch(prikr_persoonlijkheidstest_ajax.ajaxurl, {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams(form),
    });
  }

  showStartScreen() {
    const questionContainer = document.getElementById("question-container");
    questionContainer.innerHTML = `
      <div class="start-screen overflow-hidden">
        <div class="max-w-xl mx-auto">
          <h1 class="text-lg lg:text-4xl font-bold mb-4 text-center"><span class="title-after text-white mb-8 block lg:text-nowrap lg:-ml-20">Een organisatie die bij jou past?</span>
          <span class="title-after text-[#A4A3D2] mb-8 block rotate-[-4deg] ml-16">Doe de test</span> <span class="title-after text-white mb-8 block rotate-[1deg] ">en start jouw avontuur!</span></h1>
          <p class="my-12">Tijdens deze test ontdek je binnen 1 minuut of onze kernwaarden, cultuur en expertise aansluiten bij jou als persoon. Ben jij er klaar voor?</p>
          <label for="name" class="block mb-2 text-start">Naam</label>
          <div class="flex flex-row">
            <input type="text" id="name" class="block mb-4 p-2 border border-gray-300 rounded h-[50px]" placeholder="Wat is jouw naam?" />
            <button id="start-button" class="btn btn-green flex-shrink-0 flex-nowrap h-[50px] -ml-4 !rounded-bl-none">Start de test</button>
          </div>
        </div>
      </div>
    `;

    document.getElementById("start-button").addEventListener("click", () => {
      const nameInput = document.getElementById("name").value;
      if (nameInput) {
        this.name = nameInput;
        localStorage.setItem("quiz_name", this.name);
        this.showQuestion();
        this.renderTimeline();
        this.updateTimeline();
      } else {
        alert("Vul alstublieft uw naam in.");
      }
    });
  }

  showQuestion() {
    const question = this.questions[this.currentQuestionIndex];
    const questionContainer = document.getElementById("question-container");
    let inputType = question.type === "checkbox" ? "checkbox" : "radio";
    let maxAnswersAttr =
      question.type === "checkbox" && question.maxAnswers
        ? `data-max-answers="${question.maxAnswers}"`
        : "";

    questionContainer.innerHTML = `
        <h1 class="text-lg leading-6 lg:text-4xl font-bold block w-full text-center">${
          question.question
        }</h1>
        <span id="error-message" class="min-h-[50px] text-center text-red-600 block mx-auto transition transition-all" style="opacity: 0;">error</span>
        <div class="options-grid mb-8" ${maxAnswersAttr}>
            ${question.answers
              .map(
                (answer, index) => `
                <label class="option">
                    <input type="${inputType}" name="answer" value="${index}" class="hidden" ${
                  this.answers[this.currentQuestionIndex] &&
                  this.answers[this.currentQuestionIndex].includes(
                    index.toString()
                  )
                    ? "checked"
                    : ""
                } />
                    <span class="option-content">
                        <strong class="option-label">${String.fromCharCode(
                          65 + index
                        )}</strong>
                        <span class="${
                          inputType === "radio"
                            ? "option-circle"
                            : "option-square"
                        }"></span>
                        <span class="option-label-answer">${answer.label}</span>
                    </span>
                </label>
            `
              )
              .join("")}
        </div>
        <button type="button" id="back-button" class="btn px-0 btn-link flex flex-row items-center gap-2 justify-center">
            <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.64431 14L7 12.5242L1.91831 7L7 1.47577L5.64046 0L0.562621 5.52423C0.202375 5.91567 0 6.4465 0 7C0 7.5535 0.202375 8.08433 0.562621 8.47577L5.64431 14Z" fill="#00A175"/>
            </svg>
            <span class="text-tree-600">Vorige</span>
        </button>
        <button type="button" id="next-button" class="btn btn-green ml-auto self-end float-right flex flex-row items-center gap-2 justify-center">
            <span>Volgende</span>
            <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.35569 14L0 12.5242L5.08169 7L0 1.47577L1.35954 0L6.43738 5.52423C6.79763 5.91567 7 6.4465 7 7C7 7.5535 6.79763 8.08433 6.43738 8.47577L1.35569 14Z" fill="white"/>
            </svg>
        </button>
    `;

    document.querySelectorAll('input[type="checkbox"]').forEach((input) => {
      input.addEventListener("change", (event) => {
        const maxAnswers = parseInt(
          questionContainer
            .querySelector(".options-grid")
            .getAttribute("data-max-answers")
        );
        const checkedBoxes = document.querySelectorAll(
          'input[type="checkbox"]:checked'
        );
        if (checkedBoxes.length > maxAnswers) {
          event.target.checked = false;
          const errorMessage = document.getElementById("error-message");
          errorMessage.style.opacity = "1";
          errorMessage.innerText = `Je kunt maximaal ${maxAnswers} antwoorden selecteren.`;
        } else {
          const errorMessage = document.getElementById("error-message");
          errorMessage.style.opacity = "0";
          errorMessage.innerText = "";
        }
      });
    });

    document.getElementById("next-button").addEventListener("click", () => {
      console.log("from here nextQuestion");
      this.nextQuestion();
    });

    if (this.currentQuestionIndex > 0) {
      document.getElementById("back-button").style.display = "inline-flex";
      document.getElementById("back-button").addEventListener("click", () => {
        this.previousQuestion();
      });
    } else {
      document.getElementById("back-button").style.display = "none";
    }
  }

  renderTimeline() {
    const timeline = document.getElementById("timeline");
    if (timeline.classList.contains("unloaded")) {
      timeline.classList.remove("unloaded");
      timeline.style.display = "flex";
      timeline.innerHTML += this.questions
        .map(
          (_, index) => `
        <div class="step ${index === 0 ? "active" : ""}" id="step-${index + 1}">
          ${index + 1}
        </div>
      `
        )
        .join("");
    }
  }

  updateTimeline() {
    const steps = document.querySelectorAll(".step");
    steps.forEach((step, index) => {
      if (index < this.currentQuestionIndex) {
        step.classList.add("completed");
        step.classList.remove("active");
      } else if (index === this.currentQuestionIndex) {
        step.classList.add("active");
        step.classList.remove("completed");
      } else {
        step.classList.remove("active", "completed");
      }
    });
  }

  nextQuestion() {
    const question = this.questions[this.currentQuestionIndex];
    let selectedAnswers = [];

    document
      .querySelectorAll('input[name="answer"]:checked')
      .forEach((input) => {
        selectedAnswers.push(input.value);
      });

    if (selectedAnswers.length === 0 && question.type !== "checkbox") {
      const errorMessage = document.getElementById("error-message");
      errorMessage.style.opacity = "1";
      errorMessage.innerText =
        "Selecteer alstublieft een antwoord voordat je doorgaat.";
      return;
    }

    if (selectedAnswers.length === 0 && question.type === "checkbox") {
      const errorMessage = document.getElementById("error-message");
      errorMessage.style.opacity = "1";
      errorMessage.innerText =
        "Selecteer ten minste één antwoord voordat je doorgaat.";
      return;
    }

    if (
      question.type === "checkbox" &&
      selectedAnswers.length > question.maxAnswers
    ) {
      const errorMessage = document.getElementById("error-message");
      errorMessage.style.opacity = "1";
      errorMessage.innerText = `Je kunt maximaal ${question.maxAnswers} antwoorden selecteren.`;
      return;
    }

    this.answers[this.currentQuestionIndex] = selectedAnswers;
    localStorage.setItem("quiz_answers", JSON.stringify(this.answers));
    this.currentQuestionIndex++;
    localStorage.setItem("quiz_current_question", this.currentQuestionIndex);

    if (this.currentQuestionIndex < this.questions.length) {
      this.showQuestion();
      this.updateTimeline();
      scrollToTop();
    } else {
      this.calculateScore();
      this.showScore();
      localStorage.removeItem("quiz_current_question");
      localStorage.removeItem("quiz_answers");
      localStorage.removeItem("quiz_name");
    }
  }

  previousQuestion() {
    scrollToTop();
    if (this.currentQuestionIndex > 0) {
      this.currentQuestionIndex--;
      localStorage.setItem("quiz_current_question", this.currentQuestionIndex);
      this.showQuestion();
      this.updateTimeline();
    }
  }

  calculateScore() {
    this.totalScore = this.answers.reduce((total, answer, index) => {
      if (Array.isArray(answer)) {
        // If the answer is an array, it's a checkbox question
        const questionScores = answer.map(
          (ansIndex) => this.questions[index].answers[ansIndex].score
        );
        const averageScore =
          questionScores.reduce((sum, score) => sum + score, 0) / answer.length;
        return total + averageScore;
      } else {
        // If the answer is not an array, it's a radio button question
        return total + this.questions[index].answers[answer].score;
      }
    }, 0);
  }
  showScore() {
    console.log(this.totalScore);
    const progressBarContainer = document.getElementById(
      "progress-bar-container"
    );
    const progressBar = document.getElementById("progress-bar");
    const progressLabel = document.getElementById("progress-label");
    const questionContainer = document.getElementById("question-container");

    questionContainer.innerHTML = "";

    const percentage = (this.totalScore / this.calculateMaxScore()) * 100;

    // Calculate the transition duration based on the percentage
    const duration = (percentage / 100) * 10; // For example, 10 seconds for a full 100%

    // Set the transition duration dynamically
    progressBar.style.transition = `width ${duration}s ease-in-out, background-color ${duration}s ease-in-out`;

    // Show the progress bar container
    progressBarContainer.style.visibility = "visible";

    // Animate the progress bar
    progressBar.style.width = `${percentage}%`;
    if (this.totalScore > 80) {
      progressBar.style.backgroundColor = "#00A275";
    }

    this.setContent();
    this.saveToDb();

    let currentScore = 0;
    const interval = setInterval(() => {
      if (currentScore < this.totalScore) {
        currentScore++;
        progressLabel.innerText = `${Math.round(
          (currentScore / this.calculateMaxScore()) * 100
        )}% MATCH`;
      } else {
        clearInterval(interval);
        this.showFeedback();
      }
    }, (duration * 1000) / this.totalScore); // Adjust the interval duration based on the total score

    scrollToTop();
  }

  calculateMaxScore() {
    return this.questions.reduce((maxScore, question) => {
      if (question.type === "checkbox") {
        // For checkbox questions, add the max score (since we calculate average in the actual score)
        return maxScore + question.max_score;
      } else {
        // For radio button questions, add the max score directly
        return maxScore + question.max_score;
      }
    }, 0);
  }

  showFeedback() {
    const progessbarContainer = document.getElementById(
      "progress-bar-container"
    );
    const timeline = document.getElementById("timeline");
    timeline.style.display = "none";
    const feedbackElement = document.getElementById("feedback");
    const questionContainer = document.getElementById("question-container");

    let feedbackMessage = "";
    if (this.totalScore > 80) {
      feedbackMessage = this.getHighScoreMessage();
    } else if (this.totalScore > 60) {
      feedbackMessage = this.getMediumScoreMessage();
    } else {
      feedbackMessage = this.getLowScoreMessage();
    }

    questionContainer.innerHTML = `
      <div class="finish-screen">
        <div class="relative z-10">
          <h1 class="text-lg lg:text-4xl font-bold mb-2 text-center max-w-lg mx-auto"><span class="title-after text-white mb-4 block text-nowrap">
            ${
              this.totalScore > 80
                ? "YES"
                : this.totalScore > 60
                ? "POTENTIE"
                : "Helaas"
            } ${this.name} ${
      this.totalScore > 80 ? "🥳" : this.totalScore > 60 ? "🤔" : "😊"
    }!
          </span>
          <span class="title-after text-[#A4A3D2] mb-8 block text-nowrap rotate-[-4deg] ml-8">
            ${
              this.totalScore > 80
                ? "We hebben een match"
                : this.totalScore > 60
                ? "We zien overeenkomsten"
                : "We liggen niet helemaal op een lijn"
            }.
          </span></h1>
          <div id="progress-bar-placeholder"></div>
          <h2 class="text-blue-900 text-xl lg:text-4xl text-center mt-12 lg:mt-20 max-w-2xl mx-auto">
          Wat betekent dit voor mij?
          </h2>
          <span class="max-w-2xl block mx-auto text-start text-base mt-7 mb-10">
            ${feedbackMessage}
          </span>
          
        </div>
      </div>
    `;

    const placeholder = document.getElementById("progress-bar-placeholder");
    placeholder.appendChild(progessbarContainer);
    const contentPlaceholder = document.getElementById("content-placeholder");
    contentPlaceholder.style.opacity = 1;
  }

  getHighScoreMessage() {
    return "Jouw antwoorden konden niet beter bij ons passen. Wij zijn nu natuurlijk heel erg benieuwd naar jou en je verhaal! Als jij op zoek bent naar een organisatie die aansluit bij jou en jouw waarden en wilt ontdekken welke functie het meest geschikt is voor jou dan komen we graag in contact. Laat hieronder jouw gegevens achter, dan plannen we snel een online kennismaking in. En wie weet begin jij snel een nieuw avontuur!";
  }

  getMediumScoreMessage() {
    return "De meeste van jouw antwoorden komen overeen met onze waarden en cultuur. Nu is het erg belangrijk dat we onderzoeken of we in de kern bij elkaar aansluiten. Wij hebben voor jou een E-book gemaakt waarin je meer leert over The Public Network. Ben jij nog steeds enthousiast? Dan plannen we graag een online kennismaking in. Samen gaan we op zoek naar de raakvlakken en wie weet begin jij snel een nieuw avontuur bij ons!";
  }

  getLowScoreMessage() {
    return "De meeste antwoorden wijken af van wat wij jou kunnen bieden. We verwachten dan ook dat er organisaties zijn die beter bij jou en jouw wensen passen. Ben jij toch benieuwd waar de raakvlakken wél zitten en wil je dit verkennen? Dan kunnen we natuurlijk altijd een online kennismaking inplannen. Verwacht een eerlijk gesprek waarin we samen onderzoeken of we een goede match zijn.";
  }
}

document.addEventListener("DOMContentLoaded", function () {
  if (document.getElementById('quiz-container')) {
  const questions = window.questions || [];
  const quiz = new Quiz(questions);
    quiz.start();
  }
});

function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function eraseCookie(name) {
  document.cookie = name + "=; Max-Age=-99999999;";
}

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: "smooth", // This will animate the scroll
  });
}
