class VideoResponser {
  constructor(selector) {
    const $video = document.querySelector(selector);
    this.options = {
      selector,
      breakpoints: {
        default: {
          src: $video.getAttribute('data-src')
        }
      }
    };

    // get a list of video switching points and links to the videos themselves 
    $video.querySelectorAll('[data-src]').forEach(element => this.options.breakpoints[element.getAttribute('data-mw')] = {
      src: element.getAttribute('data-src'),
      webm: element.getAttribute('data-webm-src')
    });
    $video.innerHTML = ''; // we clean up so that there is nothing superfluous 

    // run the handler and track the change in screen width
    this.responseVideo(this.options);
    this.resizer();
  }

  /** Function runs on resize  */
  resizer() {
    window.addEventListener("resize", () => this.responseVideo(this.options));
  }

  /** 
   * Change src value of video link to fit screen width 
   * 
   * @param {Object} options object with options 
   */
  responseVideo(options) {
    const {
      selector,
      breakpoints
    } = options; // get options
    let $video = document.querySelector(selector);
    const widthNow = $video.getAttribute('data-width-now') || null;
    const maxBreakpoint = Math.max.apply(Math, Object.keys(breakpoints).filter(key => key <= document.body.clientWidth).map(Number));
    const nowBreakpoint = maxBreakpoint || 'default'; // choose either the maximum value, if not, then the default 
    if (widthNow && widthNow == nowBreakpoint) return; // check if the video needs to be changed 
    $video.setAttribute('data-width-now', nowBreakpoint);
    let inner = `<source src="${breakpoints[nowBreakpoint].src}" type="video/mp4">`;
    if ( breakpoints[nowBreakpoint].hasOwnProperty('webm') && breakpoints[nowBreakpoint].webm !== '' && breakpoints[nowBreakpoint].webm !== null ) {
      inner = `<source src="${breakpoints[nowBreakpoint].webm}" type="video/webm"> <source src="${breakpoints[nowBreakpoint].src}" type="video/mp4">`;
    } 
    $video.innerHTML = inner;
  }
}



document.addEventListener("DOMContentLoaded", function() {
  var lazyVideos = [].slice.call(document.querySelectorAll("video.lazy"));

  if ("IntersectionObserver" in window) {
    var lazyVideoObserver = new IntersectionObserver(function(entries, observer) {

      entries.forEach(function(video) {
        if (video.isIntersecting) {
          new VideoResponser(`#${video.target.id}`);
          video.target.classList.remove("lazy");
          lazyVideoObserver.unobserve(video.target);

        }
      });
    });

    lazyVideos.forEach(function(lazyVideo) {
      lazyVideoObserver.observe(lazyVideo);
    });
  }
});
